import React, { useState } from 'react';
import { AppRoot, Button, Input, Text, Title, Modal, Placeholder, Divider, Card } from '@telegram-apps/telegram-ui';
import './PasswordManager.css';

const PasswordManager = () => {
    const [passwords, setPasswords] = useState([]);
    const [newPassword, setNewPassword] = useState('');
    const [newService, setNewService] = useState('');
    const [category, setCategory] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const addPassword = () => {
        if (newPassword && newService) {
            setPasswords([...passwords, { service: newService, password: newPassword, category }]);
            setNewPassword('');
            setNewService('');
            setCategory('');
        }
    };

    const handleShowPassword = (password) => {
        setCurrentPassword(password);
        setShowModal(true);
    };

    return (
        <AppRoot>
            <Placeholder header="Менеджер паролей" description="Управляйте своими паролями безопасно и удобно.">
                <Title level={1}>Менеджер паролей</Title>
                <Text className="text">Управляйте своими паролями безопасно и удобно.</Text>

                {/* Форма для добавления пароля */}
                <div className="form-container">
                    <Text weight="bold">Добавить новый пароль</Text>
                    <div className="input-group">
                        <Input
                            value={newService}
                            onChange={(e) => setNewService(e.target.value)}
                            placeholder="Сервис (например, Google)"
                            label="Сервис"
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Пароль"
                            type="password"
                            label="Пароль"
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            placeholder="Категория (например, Личные)"
                            label="Категория"
                        />
                    </div>
                    <Button className="add-password-btn" onClick={addPassword}>
                        Добавить пароль
                    </Button>
                </div>

                <Divider />

                {/* Список сохраненных паролей */}
                <Text>Сохраненные пароли:</Text>
                {passwords.length === 0 ? (
                    <Text>Нет сохраненных паролей</Text>
                ) : (
                    <div className="password-list">
                        {passwords.map((entry, index) => (
                            <Card className="password-item" key={index}>
                                <div className="password-info">
                                    <Text className="service-name">{entry.service}</Text>
                                    <Text className="category-text">{entry.category}</Text>
                                </div>
                                <Button
                                    className="show-password-btn"
                                    onClick={() => handleShowPassword(entry.password)}
                                >
                                    Показать пароль
                                </Button>
                            </Card>
                        ))}
                    </div>
                )}

                {/* Модальное окно для показа пароля */}
                <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                    <Text>{showPassword ? currentPassword : '••••••••••'}</Text>
                    <Button onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? 'Скрыть' : 'Показать'}
                    </Button>
                    <Button onClick={() => setShowModal(false)}>Закрыть</Button>
                </Modal>
            </Placeholder>
        </AppRoot>
    );
};

export default PasswordManager;
